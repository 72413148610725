<template>
  <div class="excursion">
    <loader v-if="loader" />

    <div class="card" v-for="item in currentDataSet" :key="item.id">
      <lazy-card :data="item" @click.native="goToObject(item)" />
    </div>

    <span class="notification" v-if="notification">
      {{ notification }}
    </span>

    <div class="tags">
      <swiper :options="{ slidesPerView: 'auto', touchRatio: 2, freeMode: true }">
        <swiper-slide v-for="(item, index) in types" :key="`${index + 3 + 's'}`" @click.native="changeBadge(item)">
          <tag :title="item.name" :active="item.type === activeBadge.type" />
        </swiper-slide>

        <template v-if="activeBadge.subtypes">
          <swiper-slide v-for="(subtype, index) in activeBadge.subtypes" :key="index + 1" @click.native="changeSubtypeBadge(subtype)">
            <tag :title="subtype.name" :active="activeSubtypeBadge === subtype.id" />
          </swiper-slide>
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LazyCard from '@/components/Parts/LazyCard';
import Tag from "@/components/Parts/Tag";
import Loader from "@/components/Parts/Loader";

export default {
  name: "ExcursionsScreen",

  components: {
    LazyCard,
    Tag,
    Loader
  },

  computed: {
    ...mapGetters({
      routes: "GET_ROUTES",
      excursions: "GET_EXCURSIONS",
      types: "GET_TYPES",
      filtered: "GET_FILTERED_ROUTES_OR_EXCURSIONS",
      notification: "GET_ROUTES_AND_EXCURSIONS_NOTIFICATION",
      loader: "GET_ROUTES_AND_EXCURSIONS_LOADER"
    }),

    currentDataSet() {
      switch (this.isFiltered) {
        case 'all': {
          return [...this.excursions, ...this.routes];
        }
        case 'routes': {
          return this.routes;
        }
        case 'excursions': {
          return this.excursions;
        }
        case 'filtered': {
          return this.filtered;
        }
        default: {
          return [...this.excursions, ...this.routes];
        }
      }
    },
  },

  data() {
    return {
      activeBadge: {
        type: "all",
        name: "Все",
        id: 1,
      },
      activeSubtypeBadge: 0,
      isFiltered: 'all',
    }
  },

  methods: {
    async goToObject(object) {
      await this.$store.dispatch("setSelectedObject", object);
      this.$router.push({ path: `/object/${object.id}` });
    },

    changeBadge(tag) {
      if (this.activeBadge === tag) return;

      this.activeBadge = tag;
      this.activeSubtypeBadge = null

      if (tag.type === "all") {
        this.isFiltered = 'all';
      }

      if (tag.type === "route") {
        this.isFiltered = 'routes';
      }

      if (tag.type === "excursion") {
        this.isFiltered = 'excursions';
      }
    },

    changeSubtypeBadge(subtype) {
      if (this.activeSubtypeBadge === subtype.id) return;

      this.types.some(el => {
        if (el.subtypes) {
          el.subtypes.filter(item => {
            if (item.id === subtype.id) {
              this.activeSubtypeBadge = item.id
            }
          })
        }
      })

      if (this.activeBadge.type === 'route') {
        const filteredRoutes = this.routes.filter(item => item.category.id == subtype.id)
        this.$store.dispatch('GET_FILTERED_ROUTES_OR_EXCURSIONS_FROM_SERVER',
            filteredRoutes).finally(() => {
          this.isFiltered = 'filtered';
        });
      }

      if (this.activeBadge.type === 'excursion') {
        const filteredExcursions = this.excursions.filter(item => item.category.id == subtype.id)
        this.$store.dispatch('GET_FILTERED_ROUTES_OR_EXCURSIONS_FROM_SERVER',
            filteredExcursions).finally(() => {
          this.isFiltered = 'filtered';
        });
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.excursion {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  height: 1000px;
  padding: 32px;
  background-color: #1e1e1e;
  overflow-x: hidden;
  overflow-y: auto;

  .card {
    width: 488px;
    margin-bottom: 48px;
  }
}

.tags {
  position: absolute;
  bottom: 176px;
  left: 0px;
  width: 100%;
  background-color: #1e1e1e;
  border-top: 3px solid rgba(255, 255, 255, 0.16);
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  font-size: 40px;
}
</style>
